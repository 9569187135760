
.wrap{
	width: 92%;
	margin-right: 4%;
	margin-left: 4%;
}

#gnavi{
	display: none;
}

#home #cover .wrap {
    padding: 20px 0 0;
    height: auto;
}

header h1 {
  float: none;
  padding: 25px 0 0;
  width: 100%;
  text-align: center;
}

header .info{
	float: none;
  padding: 35px 0 0;
  width: 100%;
  display: none;
}



#main {
  float: none;
  width: 100%;
}

.notice {
  width: 100%;
  float: none;
}

.google_calendar {
  width: 100%;
  float: none;
}


#home_info .title p{
	width: 30%;
	img{
		width: 100%;
	}
}

#home_info table{
	width: 100%;
	display: block;
	tbody,tr{
		width: 100%;
		display: block;
		margin-bottom: 1em;
	}
	th.time{
		display: block;
		width: 100%;
	}
	td.cat{
		width: 100%;
		display: block;
	}
	td{
		padding: 0;
		padding-bottom: 0.5em;
	}
}

// .google_calendar {
//   max-width: 330px;
//   min-width: 320px;
//   margin: 2.0833% auto;
//   iframe{
//   	h1{display: none;}
//   }
// }
 
// .responsive-iframe-container {
//     position: relative;
//     padding-bottom: 56.25%;
//     padding-top: 30px;
//     height: 0;
//     overflow: hidden;
// }
// .responsive-iframe-container iframe,   
// .responsive-iframe-container object,  
// .responsive-iframe-container embed {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
// }
 

// .big-container {
//     display: none;
// }
// .small-container {
// 	display: block;
//    padding-bottom: 200%;
// }

// .google_calendar  {
// max-width: 330px;
// min-width: 300px;
// margin: 2.0833% auto;
// }

// .gcal {
// position: relative;
// padding-bottom: 120%;
// height: 0;
// }

// .gcal iframe {
// position: absolute;
// top: 0;
// left: 0;
// width: 100% !important;
// height: 100% !important;
// }

// @media only screen and (min-width: 768px) {
// .gcal { padding-bottom: 75%; }
// }

.google_calendar{
	width: 300px;
	margin-right: auto;
	margin-left: auto;
	iframe{
		width: 300px;
	}
}


#shop .top .info {
    float: none;
    width: 100%;
    margin-bottom: 1em;
}


aside{
	float: none;
	margin-top: 2em;
	width: 100%;
	text-align: center;
}



footer .main .info {
    float: none;
    padding:0;
    padding-bottom: 80px;
    width: 100%;
    color: #fff;
    text-align: left;
    background: none;
}

//footer menu

footer .main .fnavi {
  float: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255,255,255,0.95);
  opacity: 0;
  z-index: -1;
  &.open{
  	opacity: 1;
  	z-index: 5;
  }
  transition: 0.2s;
  nav ul{
  	margin-top: 10vh;
  	li{
  		width: 100%;
  		background: none;
  		margin: 0;
  		display: block;
 			&:first-child{
 				border-top: 1px solid #ccc;
 			}
  		a{
  			display: block;
	  		width: 100%;
	  		text-align: center;
	 			//margin-bottom: 1em;
	 			border-bottom: 1px solid #ccc;
	 			padding: 1.5em 0;
  			color: #333;
  			font-size: 18px;
  			font-weight: bold;
  		}
  	}
  }
}

.bar-sp{
	display: block;
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 90px;
	padding-bottom: 10px;
	//background-color: rgba(200,200,255,0.8);
	background-color: #262679;
	z-index: 6;
	color: #fff;
	.menu-btn{
		width: 46px;
		height: 46px;
		background-color: #FFF;
		text-align: center;
		position: absolute;
		right: 0.8em;
		bottom: 2.7em;
		span{
			width: 30px;
	    height: 1px;
	    display: block;
	    background: #333;
	    position: absolute;
	    left: 50%;
	    top: 35%;
	    margin-left: -15px;
	    transition: all 0.3s;
		  &.mid {
		    transform: translateX(0px);
		  }
		  &.top {
		    transform: translateY(-7px);
		  }
		  &.btm {
		    transform: translateY(7px);
		  }
		}

		&.is-open{
		  .mid {
		    transform: translateX(100px);
		    background: rgba(255, 255, 255, 0);
		  }
		  .top {
		    transform: rotate(-45deg) translateY(0px);
		  }
		  .btm {
		    transform: rotate(45deg) translateY(0px);
		  }
		}

		.menu-txt{
			color: #333;
			position: absolute;
			bottom: 0;
			line-height: 1.5;
			text-align: center;
			width: 46px;
		}
	}
	box-sizing:border-box;
	padding:0.5em 0.8em 0.8em;
	.sp-tel{
		font-size: 1.8em;
		line-height: 1.2;
		a{
			color: #FCEE39;
			margin-left: 0.2em;
		}
	}
}

//maintenance

#maintenance section .inner {
    padding: 2em 1em;
}

#maintenance section .block .txt {
    float: none;
    width: 100%;
}

#maintenance section .block .txt .maker {
    padding: 15px;
    text-align: left;
    color: #262679;
    font-weight: bold;
    background: #ebf6f6;
}

#maintenance section .block figure {
    float: none;
    width: 100%;
    text-align: center;
}

#maintenance .sub .point h3 {
    padding: 5px 5px 5px 59px;
    margin: 0 0 20px;
    color: #fff;
    font-size: 139%;
    font-weight: bold;
    background-color: #262679;
    background-repeat: no-repeat;
    background-position: 15px center;
}

#point_area {
    margin: 0;
    width: 100%;
}

#maintenance .sub .point {
    float: none;
    margin: 0;
    margin-bottom: 3em;
    width: 100%;
}

#maintenance .sub .point p {
    margin: 0 0 10px;
}

#point02 {
    min-height: inherit;
}

.c_box{
	.ttl{
		width: 100%;
		overflow: hidden;
	}
}

.c_box .block {
  padding: 1em;
}

.c_box .block .dial {
    float: none;
    width: 100%;
    img{
    	width: 100%;
    }
}

.c_box .block .mail {
    float: none;
    padding: 0;
    width: 100%;
    margin-top: 1em;
    img{
    	width: 100%;
    }
}

#maintenance .cat_navi h1{
	-webkit-background-size: cover;
	     -o-background-size: cover;
	        background-size: cover;
}

table.basic{
	//width: 90%;
}


//single
.ptable td.prev, .ptable td.next {
    width: 30%;
}

.ptable td.list {
    text-align: center;
    width: 40%;
}

//contact
#FormArea{
	table,tbody,tr,th,td{
		display: block;
		width: 100%!important;
		box-sizing:border-box;
	}
	table{
		th,td{
			padding: 1em;
		}
	}
	.wide_m {
    width: 80%;
	}
	.wide_l{
		width: 90%;
	}
}